import React from 'react'
import {Container,Row, Col, Card} from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { GoArrowRight } from "react-icons/go";
import './../../prodcuts/Products.scss';
import Header from '../../../shared/header/Header';
import Footer from '../../../shared/footer/Footer';

function IOTDevices() {
    let location = useLocation();
    return (
        <div className='products-container'>
            <Header/>
            <div className='inner-hero'>
                <Container>
                <div className='about-hero-txt'>
                    <Row className='justify-content-center text-center'>         
                        <Col lg={7} className='wow fadeInUp mt-5 mt-lg-0'>
                            <h1 className=''>Effortless Data Collection for Accurate Carbon Accounting</h1>
                            <p className='mt-4'>Consolidate all your emissions data in one secure platform, allowing you to easily track your company's carbon footprint.</p>
                            <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>Start collecting data</a>
                        </Col>
                        <Col lg="10" className='text-center pt-5'>
                            <img src={'/assets/img/products/4.webp'} className='img-fluid details-img rounded-4' alt='Products banner'/>
                        </Col>
                    </Row>
                </div>
                </Container>
            </div>

            <section className='section-padd products-details-sec trans-bg'>
                <Container>
                    <Row>
                        <Col lg={5} className='wow fadeInRight order-lg-last'>
                            <img src={'/assets/img/products/collect-data.svg'} className='img-fluid' alt='Collect Data'/>
                        </Col>
                        <Col lg={7} className='align-self-center mt-5 mt-lg-0 wow fadeInUp'>
                            <h6 className='mb-3'>Introduction</h6>
                            <h2>Collecting emissions data is the first step in your sustainability journey</h2>
                            <p className='mt-4'>This phase involves sourcing data for carbon accounting and ESG reporting, which requires coordination across various departments and stakeholders. From employee commute data to building electricity usage, our platform simplifies the process by consolidating all information into a customizable and visual dashboard. Bulk uploads, automated forms, and templates guide you through the data collection process with ease.</p>
                        </Col>
                        {/* <Col lg={1}></Col> */}
                    </Row>
                </Container>
            </section>

            <section className='section-padd-bottom savetime-section products-details-sec'>
                <Container>
                    <Row className='wow fadeInUp'>
                        <Col md={9}>
                            <h6 className='mb-4'>Our Solution</h6>
                            <h2>We offer flexible and automated data collection features that streamline your carbon accounting:</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className='mt-5 wow fadeInUp'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>01</div>
                                        Automate your data collection process with built-in AI-driven tools. Our platform automates up to 90% of the work, reducing manual effort and increasing accuracy.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className='mt-5 wow fadeInDown'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>02</div>
                                        Build a centralized source for carbon accounting data, allowing you to coordinate and organize information from various departments and locations.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className='mt-5 wow fadeInUp'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>03</div>
                                        Unify all your sustainability data in one easy-to-understand digital format, providing immediate visibility into your company's carbon footprint.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className='mt-5 wow fadeInUp'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>04</div>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className='mt-5 wow fadeInUp'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>05</div>
                                        It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className='mt-5 wow fadeInUp'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>06</div>
                                        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='section-padd-bottom opportunity-section'>
              <Container>
                <div className='details-slide'>
                    <Row>
                    <Col lg={6}>
                        <div className='oppor-left'>
                            <h2 className='mb-4'>Why is Collecting Emissions Data Essential?</h2>
                            <p>Accurate emissions data is crucial for a successful decarbonization journey. Here's why:</p>
                            <a href='/book-a-demo' className='btn btn-primary px-4 mt-5'>Start collecting data</a>
                        </div>
                    </Col>
                    <Col lg={6} className='pt-5 pt-lg-0'>
                        <div className='opportunity-box wow fadeInUp mt-0'>
                            <Row className='mt-2'>
                                <Col sm={12} className='align-self-center'>
                                    <div className='no-icon-c'><img src={'/assets/img/icons/build-acfa.svg'} className="img-fluid" alt='Build an Accurate Carbon Footprint Assessment'/></div>
                                    <h5 className='pb-3'>Build an Accurate Carbon Footprint Assessment</h5>

                                    <div className='text-oppor'>Data accuracy is key for a reliable carbon footprint. With consistent and transparent data, companies can start their journey toward decarbonization with confidence.</div>
                                </Col>
                            </Row>
                        </div>

                        <div className='opportunity-box wow fadeInUp'>
                            <Row className='mt-2'>
                            <Col sm={12} className='align-self-center'>
                                <div className='no-icon-c'><img src={'/assets/img/icons/identify-emission-hotspot.svg'} className="img-fluid" alt='Identify Emissions Hotspots'/></div>
                                <h5 className='pb-3'>Identify Emissions Hotspots</h5>

                                <div className='text-oppor'>Precise data collection helps you pinpoint major emissions sources, allowing you to create a focused reduction plan that delivers significant sustainability improvements.</div>
                            </Col>
                            </Row>
                        </div>

                        <div className='opportunity-box wow fadeInUp'>
                            <Row className='mt-2'>
                            <Col sm={12} className='align-self-center'>
                                <div className='no-icon-c'><img src={'/assets/img/icons/understand-scope.svg'} className="img-fluid" alt='Understand Your Scope'/></div>
                                <h5 className='pb-3'>Understand Your Scope 1, 2, and 3 Emissions</h5>

                                <div className='text-oppor'>Gain a comprehensive view of your emissions across all scopes, providing the insight needed to develop effective sustainability strategies.</div>
                            </Col>
                            </Row>
                        </div>
                    </Col>
                    </Row>
                </div>
              </Container>

            </section>

            <section className='section-padd cta-section'>
                <Container>
                <div className='text-center cta-text wow fadeInUp'>
                    <h2 className='mb-4 mb-lg-5'>Begin your decarbonisation journey today</h2>
                    <p>Our sustainability experts will advise you on an effective strategy for your business.</p>
                    <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>Book A Demo</a>
                </div>
                </Container>
            </section>
            <Footer/>
        </div>
    )
}

export default IOTDevices