import React from 'react'
import {Container,Row, Col, Card} from 'react-bootstrap';
import './../prodcuts/Products.scss';
import Header from '../../shared/header/Header';
import Footer from '../../shared/footer/Footer';

function OurSolutions() {
    return (
        <div className='products-container'>
            <Header/>

            <div className='inner-hero'>
                <Container>
                <div className='about-hero-txt'>
                    <Row className='justify-content-center text-center'>                
                    <Col lg={10} className='wow fadeInUp mt-5 mt-lg-0'>
                        <h1 className=''>Achieve True Net-Zero with Our Carbon Management Platform</h1>
                        <p className='mt-4'>Take control of your carbon footprint and make measurable progress toward net-zero. Our platform, along with personalized support, empowers you to achieve your sustainability goals.</p>
                        <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>Book A Demo</a>
                    </Col>
                    <Col lg="11" className='text-center section-padd-top'>
                        <img src={'/assets/img/products/product-banner.webp'} className='img-fluid' alt='Products banner'/>
                    </Col>
                    </Row>
                </div>
                </Container>
            </div>

            <section className='section-padd-bottom products-types'>
                <Container>
                    <Row className='section-padd'>
                        <Col lg={4} className='wow fadeInLeft'>
                        <img src={'/assets/img/products/collect-data.svg'} className='img-fluid' alt='Collect Data'/>
                        </Col>
                        <Col lg={1}></Col>
                        <Col lg={7} className='align-self-center mt-5 mt-lg-0 wow fadeInUp'>
                            <h6 className='mb-3'>Devices</h6>
                            <h2>IOT Devices</h2>
                            <p className='mt-4'>Collect emissions data swiftly with AI-driven tools that streamline the entire process. Our platform ensures accuracy and transparency, making it easier to consolidate data across your organization.</p>

                            <a href='/IOT-devices' className='btn btn-primary px-4 mt-4'>Automate your Data Collection</a>
                        </Col>
                    </Row>

                    <Row className='section-padd'>
                        <Col lg={4} className='wow fadeInLeft'>
                        <img src={'/assets/img/products/report-data.svg'} className='img-fluid' alt='Analyze Data'/>
                        </Col>
                        <Col lg={1}></Col>
                        <Col lg={7} className='align-self-center mt-5 mt-lg-0 wow fadeInUp'>
                            <h6 className='mb-3'>Automations</h6>
                            <h2>AI Automations</h2>
                            <p className='mt-4'>Create comprehensive ESG reports faster with AI-powered assistance. Talk to your data, leverage automation, and generate reports that align with our sustainability frameworks. Our platform helps you track progress towards your net-zero goals.</p>

                            <a href='/AI-automations' className='btn btn-primary px-4 mt-4'>Create ESG Reports</a>
                        </Col>
                    </Row>

                </Container>
            </section>

            <section className='savetime-section'>
                <Container>
                    <Row className='wow fadeInUp'>
                        <Col md={6}>
                            <h6 className='mb-4'>PRODUCTS & SERVICES</h6>
                            <h2>Save time, resources, and focus on real impact</h2>
                        </Col>
                    </Row>
                    <Row className='section-padd'>
                        <Col md={3} className='wow fadeInUp'>
                            <Card className='border-0 sp-box'>
                                <Card.Body>
                                    <h6>Hardware</h6>
                                    <Card.Title>Streamlined IoT Integration</Card.Title>
                                    <Card.Text>
                                        Enhance user experience with seamless IoT device integration.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} className='mt-4 mt-md-0 wow fadeInDown'>
                            <Card className='border-0 sp-box'>
                                <Card.Body>
                                    <h6>Software</h6>
                                    <Card.Title>Comprehensive Software Suite</Card.Title>
                                    <Card.Text>
                                        Simplify operations with our versatile software and integrations.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} className='mt-4 mt-md-0 wow fadeInUp'>
                            <Card className='border-0 sp-box'>
                                <Card.Body>
                                    <h6>Data</h6>
                                    <Card.Title>Automated Data Management</Card.Title>
                                    <Card.Text>
                                        Streamline processes and ensure data security with automated management.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} className='mt-4 mt-md-0 wow fadeInUp'>
                            <Card className='border-0 sp-box'>
                                <Card.Body>
                                    <h6>Community</h6>
                                    <Card.Title>Community Resource Hub</Card.Title>
                                    <Card.Text>
                                        Access simplified frameworks and resources for seamless collaboration and innovation.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='section-padd cta-section'>
                <Container>
                <div className='text-center cta-text wow fadeInUp'>
                    <h2 className='mb-4 mb-lg-5'>Begin your decarbonisation journey today</h2>
                    <p>Our sustainability experts will advise you on an effective strategy for your business.</p>
                    <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>Book A Demo</a>
                </div>
                </Container>
            </section>

            <Footer/>

        </div>
    )
}

export default OurSolutions