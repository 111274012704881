import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Header from '../../shared/header/Header';
import Footer from "../../shared/footer/Footer";
import './Bookdemo.scss';
import { COUNTRY_LIST } from '../../services/countryData';
import { RECAPTCHA_API_KEY } from '../../services/constants';
import ReCAPTCHA from "react-google-recaptcha";
import { DemoBookingApiService } from '../../services/apiService';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function Bookdemo() {
    const navigate = useNavigate();
    const [tempPhone, setTempPhone] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const [RecaptchaToken, setRecaptchaToken] = useState("");
    const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [company, setCompany] = useState("");
    const [numberOfEmployees, setNumberOfEmployees] = useState("");
    const [industry, setIndustry] = useState("");
    const [country, setCountry] = useState("");

    const handleCaptchaVerification = (value) => {
        setRecaptchaToken(value);
        setIsRecaptchaVerified(true);
    }

    const handleBookDemoFormSubmit = (e) => {
        if (!isRecaptchaVerified) {
            return;
        }

        let form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setIsFormValid(true);
            window.scrollTo(0, 0);
            return;
        }
        setIsFormValid(true);
        e.preventDefault();
        let formData = {
            firstname,
            lastname,
            email,
            phone,
            jobTitle,
            company,
            numberOfEmployees,
            industry,
            country
        };
        DemoBookingApiService.addDemoBooking(formData)
            .then(response => {
                if (response.data.success === true) {
                    toast.success("Thank you for your interest! We will get back to you shortly.");
                    navigate("/");
                }
            })
            .catch(error => {
                console.log(error);
                toast.error("Something went wrong. Please try again later.");
            })
    }

    return (
        <div className='bookdemo-cont planeHeader'>

            <Header />

            <section className='bookdemo-con section-padd'>
                <Container>

                    <Row>
                        <Col lg={6} className='wow fadeInLeft'>
                            <h6 className='mb-4'>contact</h6>
                            <h2 className='mb-4'>Discover Our Platform</h2>
                            <p>Bharat Carbon provides a modular platform that enables businesses to measure and analyze their carbon footprint, report on global ESG frameworks and understand value chains at a granular level based on what your priorities are as a business.</p>
                            <p className='mb-0'>Our dedicated team is ready to engage in meaningful conversations, address your concerns, and explore opportunities for collaboration. Contact us today and let's start the conversation!</p>

                            <Row className='mt-1'>
                                <Col md={4} className='mt-4'>
                                    <Card className='border-0 address-block'>
                                        <Card.Body>
                                            <div className='card-text'>
                                                <h5>MUMBAI, INDIA</h5>
                                                <p className='mb-0'>Spectrum Towers, Mindspace, Malad (W), Mumbai, Maharashtra - 400064</p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} className='mt-4'>
                                    <Card className='border-0 address-block'>
                                        <Card.Body>
                                            <div className='card-text'>
                                                <h5>PUNE, INDIA</h5>
                                                <p className='mb-0'>Sai Hira, Mundhwa, Pune, Maharashtra - 411036</p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} className='mt-4'>
                                    <Card className='border-0 address-block'>
                                        <Card.Body>
                                            <div className='card-text'>
                                            <h5>NEW JERSEY, USA</h5>
                                            <p className='mb-0'>320 South Broad Street, Ridgewood, NJ - 07450</p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} className='mt-5 mt-lg-0 wow fadeInUp'>
                            <div className='contact-form'>
                                <Form onSubmit={handleBookDemoFormSubmit} noValidate validated={isFormValid}>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>First Name<sup>*</sup></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Mother"
                                                    value={firstname}
                                                    onChange={(e) => setFirstname(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Last Name<sup>*</sup></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Earth"
                                                    value={lastname}
                                                    onChange={(e) => setLastname(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Business email<sup>*</sup></Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="mother.earth@savingtheplanet.com"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Phone Number<sup>*</sup></Form.Label>
                                                <PhoneInput
                                                    country={"in"}
                                                    enableSearch={true}
                                                    value={tempPhone}
                                                    onChange={(val, _1, _2, finalVal) => {
                                                        setTempPhone(val);
                                                        setPhone(finalVal);
                                                    }}
                                                    required
                                                    countryCodeEditable={false}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Job title<sup>*</sup></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="ex:Sustainability Head"
                                                    value={jobTitle}
                                                    onChange={(e) => setJobTitle(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Company name<sup>*</sup></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="ex:Planet Earth Limited"
                                                    value={company}
                                                    onChange={(e) => setCompany(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Number of employees<sup>*</sup></Form.Label>
                                                <Form.Select
                                                    value={numberOfEmployees}
                                                    onChange={(e) => setNumberOfEmployees(e.target.value)}
                                                    required
                                                >
                                                    <option value={''}>Please Select</option>
                                                    <option value={'1 - 10'}>1 - 10</option>
                                                    <option value={'10 - 50'}>10 - 50</option>
                                                    <option value={'50 - 100'}>50 - 100</option>
                                                    <option value={'100 - 500'}>100 - 500</option>
                                                    <option value={'500 - 1000'}>500 - 1000</option>
                                                    <option value={'1000+'}>1000+</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Industry<sup>*</sup></Form.Label>
                                                <Form.Select
                                                    value={industry}
                                                    onChange={(e) => setIndustry(e.target.value)}
                                                    required
                                                >
                                                    <option value={''}>Please Select</option>
                                                    <option value={'Manufacturing'}>Manufacturing</option>
                                                    <option value={'Information Technology'}>Information Technology</option>
                                                    <option value={'Real Estate'}>Real Estate</option>
                                                    <option value={'Retail'}>Retail</option>
                                                    <option value={'Restaurants'}>Restaurants</option>
                                                    <option value={'Hotels'}>Hotels</option>
                                                    <option value={'Other'}>Other</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Country<sup>*</sup></Form.Label>
                                                <Form.Select
                                                    value={country}
                                                    onChange={(e) => setCountry(e.target.value)}
                                                    required
                                                >
                                                    <option value={''}>Please Select</option>
                                                    {COUNTRY_LIST.map((country, index) => {
                                                        return (
                                                            <option key={index} value={country.name}>{country.name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <ReCAPTCHA
                                                sitekey={RECAPTCHA_API_KEY}
                                                onChange={handleCaptchaVerification}
                                                onExpired={() => {
                                                    setIsRecaptchaVerified(false)
                                                    setRecaptchaToken("")
                                                }}
                                                required
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <Button
                                                variant="primary"
                                                className='Rounded px-5 mt-4'
                                                disabled={!isRecaptchaVerified}
                                                type="submit"
                                            >
                                                Book A Demo
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>

                            <Row className='mt-5 d-flex d-lg-none'>
                                <Col md={4} className='mt-3 mt-md-0'>
                                    <Card className='border-0 address-block'>
                                        <Card.Body>
                                            <div className='card-text'>
                                            <h5>MUMBAI, INDIA</h5>
                                            <p className='mb-0'>Spectrum Towers, Mindspace, Malad (W), Mumbai, Maharashtra - 400064</p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} className='mt-3 mt-md-0'>
                                    <Card className='border-0 address-block'>
                                        <Card.Body>
                                            <div className='card-text'>
                                                <h5>PUNE, INDIA</h5>
                                                <p className='mb-0'>Sai Hira, Mundhwa, Pune, Maharashtra - 411036</p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} className='mt-3 mt-md-0'>
                                    <Card className='border-0 address-block'>
                                        <Card.Body>
                                            <div className='card-text'>
                                            <h5>NEW JERSEY, USA</h5>
                                            <p className='mb-0'>320 South Broad Street, Ridgewood, NJ - 07450</p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />
        </div>
    )
}

export default Bookdemo