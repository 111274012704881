import React, { useEffect, useState } from 'react'
import {Container,Row, Col, Card} from 'react-bootstrap';
import { GoArrowRight } from "react-icons/go";
import { useLocation } from "react-router-dom";
import Header from '../../../shared/header/Header';
import Footer from '../../../shared/footer/Footer';
import './ProductDetails.scss';
import { BlogApiService, SubCategoryApiService } from '../../../services/apiService';
import { COVER_IMAGE_BASE_URL } from '../../../services/constants';

function ImproveStrategy() {
    let location = useLocation(); 
    const [casestudy, setCaseStudy] = useState([]);
    const [subCategories, setSubCategories] = useState([])

    const getCaseStudyBlog = async () => {
        BlogApiService.getCaseStudyTopThree()
            .then(async (res) => {
                if (res.data) {
                    setCaseStudy(await res.data.blog);
                    console.log(casestudy)
                    console.log(res.data.blog);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    const getSubCategories = () => {
        SubCategoryApiService.getSubCategories()
            .then(response => {
                if (response.data.success === true) {
                    setSubCategories(response.data.data)
                }
            })
            .catch(e => {
                console.log(e)
            })
    }
    useEffect(() => {
        getSubCategories();
        getCaseStudyBlog();
    }, [])
    return (
        <div className='products-container ll-logo-display'>

           <Header/>

            <div className='inner-hero'>
                <Container>
                <div className='about-hero-txt'>
                    <div className='p-details-nav'>
                        <Row className='justify-content-center'>
                            <Col xs={4} md={3} lg={2}>
                                <a className={`btn-primary ${location.pathname === "/collect-data" ? "active" : ""} btn rounded-pill`} href='/collect-data'>01 Collect</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a  href='/analyze-data' className={`btn-primary ${location.pathname === "/analyze-data" ? "active" : ""} btn rounded-pill`}>02 Analyze</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/report-data' className={`btn-primary ${location.pathname === "/report-data" ? "active" : ""} btn rounded-pill`}>03 Report</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/build-strategy' className={`btn-primary ${location.pathname === "/build-strategy" ? "active" : ""} btn rounded-pill`}>04 Build</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/reduce-emissions' className={`btn-primary ${location.pathname === "/reduce-emissions" ? "active" : ""} btn rounded-pill`}>05 Reduce</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/improve-strategy' className={`btn-primary ${location.pathname === "/improve-strategy" ? "active" : ""} btn rounded-pill`}>06 Improve</a>
                            </Col>
                        </Row>
                    </div>
                    <Row className='justify-content-center text-center'>         
                        <Col lg={7} className='wow fadeInUp mt-5 mt-lg-0'>
                            <h1 className=''>Improve Your Strategy for Sustainable Growth</h1>
                            <p className='mt-4'>Track key KPIs and enhance your decarbonization strategy. Use our AI-driven tools to track progress & communicate your sustainability progress to internal and external stakeholders.</p>
                            <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>Book A Demo</a>
                        </Col>
                        <Col lg="11" className='text-center pt-5'>
                            <img src={'/assets/img/products/collect-data.png'} className='img-fluid details-img' alt='Products banner'/>
                        </Col>
                    </Row>
                </div>
                </Container>
            </div>

            {/* <section className='trustedby'>
                <div className='clients-section'>
                <div className="slider">
                    <div className="slide-track">
                        <div className="slide"><img src={'/assets/img/clients/c1.png'} alt='Client 1'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c2.png'} alt='Client 2'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c3.png'} alt='Client 3'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c4.png'} alt='Client 4'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c5.png'} alt='Client 5'/></div>

                        <div className="slide"><img src={'/assets/img/clients/c1.png'} alt='Client 1'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c2.png'} alt='Client 2'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c3.png'} alt='Client 3'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c4.png'} alt='Client 4'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c5.png'} alt='Client 1'/></div>
                    </div>
                </div>
                </div>
            </section> */}

            <section className='section-padd products-details-sec trans-bg'>
                <Container>
                    <Row>
                        <Col lg={5} className='wow fadeInRight order-lg-last'>
                            <img src={'/assets/img/products/improve-record.svg'} className='img-fluid' alt='Collect Data'/>
                        </Col>
                        <Col lg={7} className='align-self-center mt-5 mt-lg-0 wow fadeInUp'>
                            <h6 className='mb-3'>Introduction</h6>
                            <h2>Improving your sustainability strategy involves continuously assessing and refining your approach to decarbonization.</h2>
                            <p className='mt-4'>With Bharat Carbon, you can track important KPIs, monitor reduction actions, and iterate on your strategy to ensure you're on the path to net-zero. Our platform helps you identify gaps, optimize processes, and make data-driven decisions to meet your sustainability goals. Additionally, we offer a generative AI module that assists in creating emails and social media content, helping you communicate your sustainability efforts and build a strong community within and outside your organization.</p>
                        </Col>
                        {/* <Col lg={1}></Col> */}
                    </Row>
                </Container>
            </section>

            <section className='section-padd-bottom savetime-section products-details-sec'>
                <Container>
                    <Row className='wow fadeInUp'>
                        <Col md={9}>
                            <h6 className='mb-4'>Our Solution</h6>
                            <h2>Our platform is designed to help you improve your sustainability strategy by:</h2>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col md={4} className='wow fadeInUp'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>01</div>
                                        Offering a comprehensive tracking module that enables you to monitor key sustainability metrics and identify areas for improvement.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className='mt-4 mt-md-0 wow fadeInDown'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>02</div>
                                        Providing tools to track reduction actions and assess their effectiveness in meeting your decarbonization goals.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className='mt-4 mt-md-0 wow fadeInUp'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>03</div>
                                        Facilitating stakeholder communication with AI-generated content for emails and social media, allowing you to keep everyone informed and engaged.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='section-padd-bottom opportunity-section'>
              <Container>
                <div className='details-slide'>
                    <Row>
                    <Col lg={6}>
                        <div className='oppor-left'>
                            <h2>Why Is Improving Your Sustainability Strategy Crucial?</h2>
                            <p className='mt-4'>Continuous improvement in sustainability is essential for several reasons:</p>
                            <a href='/book-a-demo' className='btn btn-primary px-4 mt-5'>Start improving your strategy</a>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='opportunity-box wow fadeInUp mt-0'>
                            <Row className='mt-2'>
                                <Col sm={12} className='align-self-center'>
                                    <div className='no-icon-c'><img src={'/assets/img/icons/resource-efficiency-cost-saving.svg'} className="img-fluid" alt='Resource Efficiency and Cost Savings'/></div>
                                    <h5 className='pb-3'>Resource Efficiency and Cost Savings</h5>

                                    <div className='text-oppor'>By improving your sustainability strategy, you can identify and implement measures to enhance operational efficiency, reduce energy consumption, and minimize waste. These efforts often lead to significant cost savings.</div>
                                </Col>
                            </Row>
                        </div>

                        <div className='opportunity-box wow fadeInUp'>
                            <Row className='mt-2'>
                            <Col sm={12} className='align-self-center'>
                                <div className='no-icon-c'><img src={'/assets/img/icons/regulatory-compliance.svg'} className="img-fluid" alt='Regulatory Compliance'/></div>
                                <h5 className='pb-3'>Regulatory Compliance</h5>

                                <div className='text-oppor'>Staying ahead of evolving environmental regulations is crucial. A well-improved sustainability strategy helps you comply with changing standards, reducing the risk of penalties and legal issues while avoiding reputational damage.</div>
                            </Col>
                            </Row>
                        </div>

                        <div className='opportunity-box wow fadeInUp'>
                            <Row className='mt-2'>
                            <Col sm={12} className='align-self-center'>
                                <div className='no-icon-c'><img src={'/assets/img/icons/investor-stakeholder.svg'} className="img-fluid" alt='Investor and Stakeholder Expectations'/></div>
                                <h5 className='pb-3'>Investor and Stakeholder Expectations</h5>
                                <div className='text-oppor'>Investors and stakeholders increasingly focus on companies with robust sustainability practices. By continually enhancing your strategy, you attract a broader range of investors, access capital more easily, and strengthen your reputation among stakeholders.</div>
                            </Col>
                            </Row>
                        </div>
                    </Col>
                    </Row>
                </div>
              </Container>

            </section>

            <section className='section-padd cta-section'>
                <Container>
                <div className='text-center cta-text wow fadeInUp'>
                    <h2 className='mb-4 mb-lg-5'>Begin your decarbonisation journey today</h2>
                    <p>Our sustainability experts will advise you on an effective strategy for your business.</p>
                    <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>Book A Demo</a>
                </div>
                </Container>
            </section>

            <section className='section-padd exploreStory'>
                <Container>
                    <h6 className='mb-4'>CASE STUDIES</h6>
                    {casestudy.length > 0 && <Row>
                        {casestudy.map((blog) => (<Col lg={4} className='mt-5 wow fadeInUp'>
                            <div className='casestudy-box'>
                                <div className='casestudy-image'>
                                    <img src={COVER_IMAGE_BASE_URL + blog?.coverimg} className='img-fluid' alt='case Study' />
                                </div>
                                <div className='casestudy-text'>
                                    <h6>{subCategories.find(sc => sc.id === parseInt(blog.subcategory))?.name}</h6>
                                    <h5>{blog.title}</h5>
                                    <a href={`/article/${blog.slug}`} className='btn btn-outline-primary px-4 mt-2 mt-lg-4'>Read Case Study <GoArrowRight /></a>
                                </div>
                            </div>
                        </Col>
                        ))
                        }
                    </Row>}
                </Container>
            </section>

            <section className='footerpro-links'>
                <Container>
                    <div className='p-details-nav'>
                        <Row className='justify-content-center'>
                            <Col xs={4} md={3} lg={2}>
                                <a className={`btn-primary ${location.pathname === "/collect-data" ? "active" : ""} btn rounded-pill`} href='/collect-data'>01 Collect</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a  href='/analyze-data' className={`btn-primary ${location.pathname === "/analyze-data" ? "active" : ""} btn rounded-pill`}>02 Analyze</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/report-data' className={`btn-primary ${location.pathname === "/report-data" ? "active" : ""} btn rounded-pill`}>03 Report</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/build-strategy' className={`btn-primary ${location.pathname === "/build-strategy" ? "active" : ""} btn rounded-pill`}>04 Build</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/reduce-emissions' className={`btn-primary ${location.pathname === "/reduce-emissions" ? "active" : ""} btn rounded-pill`}>05 Reduce</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/improve-strategy' className={`btn-primary ${location.pathname === "/improve-strategy" ? "active" : ""} btn rounded-pill`}>06 Improve</a>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

           <Footer/>
            
        </div>
    )
}

export default ImproveStrategy