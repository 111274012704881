import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { GoArrowRight } from "react-icons/go";
import { useLocation } from "react-router-dom";
import Header from '../../../shared/header/Header';
import Footer from '../../../shared/footer/Footer';
import './ProductDetails.scss';
import { BlogApiService, SubCategoryApiService } from '../../../services/apiService';
import { COVER_IMAGE_BASE_URL } from '../../../services/constants';

function BuildStrategy() {
    const [casestudy, setCaseStudy] = useState([]);
    const [subCategories, setSubCategories] = useState([])

    const getCaseStudyBlog = async () => {
        BlogApiService.getCaseStudyTopThree()
            .then(async (res) => {
                if (res.data) {
                    setCaseStudy(await res.data.blog);
                    console.log(casestudy)
                    console.log(res.data.blog);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    const getSubCategories = () => {
        SubCategoryApiService.getSubCategories()
            .then(response => {
                if (response.data.success === true) {
                    setSubCategories(response.data.data)
                }
            })
            .catch(e => {
                console.log(e)
            })
    }
    useEffect(() => {
        getSubCategories();
        getCaseStudyBlog();
    }, [])
    let location = useLocation();
    return (
        <div className='products-container ll-logo-display'>

            <Header />

            <div className='inner-hero'>
                <Container>
                    <div className='about-hero-txt'>
                        <div className='p-details-nav'>
                            <Row className='justify-content-center'>
                                <Col xs={4} md={3} lg={2}>
                                    <a className={`btn-primary ${location.pathname === "/collect-data" ? "active" : ""} btn rounded-pill`} href='/collect-data'>01 Collect</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/analyze-data' className={`btn-primary ${location.pathname === "/analyze-data" ? "active" : ""} btn rounded-pill`}>02 Analyze</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/report-data' className={`btn-primary ${location.pathname === "/report-data" ? "active" : ""} btn rounded-pill`}>03 Report</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/build-strategy' className={`btn-primary ${location.pathname === "/build-strategy" ? "active" : ""} btn rounded-pill`}>04 Build</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/reduce-emissions' className={`btn-primary ${location.pathname === "/reduce-emissions" ? "active" : ""} btn rounded-pill`}>05 Reduce</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/improve-strategy' className={`btn-primary ${location.pathname === "/improve-strategy" ? "active" : ""} btn rounded-pill`}>06 Improve</a>
                                </Col>
                            </Row>
                        </div>
                        <Row className='justify-content-center text-center'>
                            <Col lg={7} className='wow fadeInUp mt-5 mt-lg-0'>
                                <h1 className=''>Build a Strategy for True Net-Zero</h1>
                                <p className='mt-4'>Create short-term and long-term plans with integrative thinking and scenario planning. Our platform helps you build science-backed strategies tailored to your business's goals and budget.</p>
                                <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>Book a demo</a>
                            </Col>
                            <Col lg="11" className='text-center pt-5'>
                                <img src={'/assets/img/products/collect-data.png'} className='img-fluid details-img' alt='Products banner' />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            {/* <section className='trustedby'>
                <div className='clients-section'>
                <div className="slider">
                    <div className="slide-track">
                        <div className="slide"><img src={'/assets/img/clients/c1.png'} alt='Client 1'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c2.png'} alt='Client 2'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c3.png'} alt='Client 3'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c4.png'} alt='Client 4'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c5.png'} alt='Client 5'/></div>

                        <div className="slide"><img src={'/assets/img/clients/c1.png'} alt='Client 1'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c2.png'} alt='Client 2'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c3.png'} alt='Client 3'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c4.png'} alt='Client 4'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c5.png'} alt='Client 1'/></div>
                    </div>
                </div>
                </div>
            </section> */}

            <section className='section-padd products-details-sec trans-bg'>
                <Container>
                    <Row>
                        <Col lg={5} className='wow fadeInRight order-lg-last'>
                            <img src={'/assets/img/products/build-strategy.svg'} className='img-fluid' alt='Collect Data' />
                        </Col>
                        <Col lg={7} className='align-self-center mt-5 mt-lg-0 wow fadeInUp'>
                            <h6 className='mb-3'>Introduction</h6>
                            <h2>Building a comprehensive decarbonization strategy involves considering various factors, including internal and external environments.</h2>
                            <p className='mt-4'>Bharat Carbon helps you set science-based targets and develop strategies that align with your company's purpose, governance, performance, risk and opportunity assessments, and culture. We focus on both tangible and intangible value to create a pathway toward achieving net-zero emissions.</p>
                        </Col>
                        {/* <Col lg={1}></Col> */}
                    </Row>
                </Container>
            </section>

            <section className='section-padd-bottom savetime-section products-details-sec'>
                <Container>
                    <Row className='wow fadeInUp'>
                        <Col md={9}>
                            <h6 className='mb-4'>Our Solution</h6>
                            <h2>Our approach to building a decarbonization strategy includes:</h2>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col md={4} className='wow fadeInUp'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>01</div>
                                        Using integrative thinking and scenario planning to help you create a science-based strategy tailored to your business interests, budgets, and goals.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className='mt-4 mt-md-0 wow fadeInDown'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>02</div>
                                        Analyzing your internal and external environments to identify opportunities for reducing emissions.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className='mt-4 mt-md-0 wow fadeInUp'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>03</div>
                                        Providing a comprehensive strategy that encompasses purpose, governance, performance, risk management, and company culture.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className='mt-4 wow fadeInUp'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>04</div>
                                        Applying both short-term and long-term planning to ensure you have a roadmap for reaching your decarbonization targets.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='section-padd-bottom opportunity-section'>
                <Container>
                    <div className='details-slide'>
                        <Row>
                            <Col lg={6}>
                                <div className='oppor-left'>
                                    <h2>Why Is Target Setting Important to Reach Your Climate Goals?</h2>
                                    <p className='mt-4'>Setting science-based targets is critical for a successful decarbonization strategy. Here's why it matters:</p>
                                    <a href='/book-a-demo' className='btn btn-primary px-4 mt-5'>Set Settings Targets</a>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='opportunity-box wow fadeInUp mt-0'>
                                    <Row className='mt-2'>
                                        <Col sm={12} className='align-self-center'>
                                            <div className='no-icon-c'><img src={'/assets/img/icons/score-higher-ESG-reporting.svg'} className="img-fluid" alt='Score Higher on ESG Reporting' /></div>
                                            <h5 className='pb-3'>Score Higher on ESG Reporting</h5>

                                            <div className='text-oppor'>Companies with net-zero targets tend to score better on emissions and disclosure criteria, especially if they set interim goals.</div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className='opportunity-box wow fadeInUp'>
                                    <Row className='mt-2'>
                                        <Col sm={12} className='align-self-center'>
                                            <div className='no-icon-c'><img src={'/assets/img/icons/enhance-brand-reputation.svg'} className="img-fluid" alt='Enhance Brand Reputation' /></div>
                                            <h5 className='pb-3'>Enhance Brand Reputation</h5>

                                            <div className='text-oppor'>Setting clear targets strengthens brand reputation, showing that your company is committed to climate action.</div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className='opportunity-box wow fadeInUp'>
                                    <Row className='mt-2'>
                                        <Col sm={12} className='align-self-center'>
                                            <div className='no-icon-c'><img src={'/assets/img/icons/drive-innovation.svg'} className="img-fluid" alt='Drive Innovation' /></div>
                                            <h5 className='pb-3'>Drive Innovation</h5>
                                            <div className='text-oppor'>Target setting encourages companies to innovate by rethinking operations to reduce emissions, leading to more efficient and sustainable processes.</div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className='opportunity-box wow fadeInUp'>
                                    <Row className='mt-2'>
                                        <Col sm={12} className='align-self-center'>
                                            <div className='no-icon-c'><img src={'/assets/img/icons/increase-customer-loyalty.svg'} className="img-fluid" alt='Increase Customer Loyalty' /></div>
                                            <h5 className='pb-3'>Increase Customer Loyalty</h5>
                                            <div className='text-oppor'>Consumers appreciate companies with concrete sustainability commitments, leading to greater customer loyalty.</div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className='opportunity-box wow fadeInUp'>
                                    <Row className='mt-2'>
                                        <Col sm={12} className='align-self-center'>
                                            <div className='no-icon-c'><img src={'/assets/img/icons/retain-talent.svg'} className="img-fluid" alt='Retain Talent' /></div>
                                            <h5 className='pb-3'>Retain Talent</h5>
                                            <div className='text-oppor'>A comprehensive sustainability strategy can improve employee engagement and reduce turnover, as employees are increasingly drawn to companies with strong sustainability values.</div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>

            </section>

            <section className='section-padd cta-section'>
                <Container>
                    <div className='text-center cta-text wow fadeInUp'>
                        <h2 className='mb-4 mb-lg-5'>Begin your decarbonisation journey today</h2>
                        <p>Our sustainability experts will advise you on an effective strategy for your business.</p>
                        <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>Book A Demo</a>
                    </div>
                </Container>
            </section>

            <section className='section-padd exploreStory'>
                <Container>
                    <h6 className='mb-4'>CASE STUDIES</h6>
                    {casestudy.length > 0 && <Row>
                        {casestudy.map((blog) =>
                            <Col lg={4} className='mt-5 wow fadeInUp'>
                                 <div className='casestudy-box'>
                                <div className='casestudy-image'>
                                    <img src={COVER_IMAGE_BASE_URL + blog?.coverimg} className='img-fluid' alt='case Study' />
                                </div>
                                <div className='casestudy-text'>
                                    <h6>{subCategories.find(sc => sc.id === parseInt(blog.subcategory))?.name}</h6>
                                    <h5>{blog.title}</h5>
                                    <a href={`/article/${blog.slug}`} className='btn btn-outline-primary px-4 mt-2 mt-lg-4'>Read Case Study <GoArrowRight /></a>
                                </div>
                            </div>
                            </Col>
                        )
                        }
                    </Row>
                    }
                </Container>
            </section>

            <section className='footerpro-links'>
                <Container>
                    <div className='p-details-nav'>
                        <Row className='justify-content-center'>
                            <Col xs={4} md={3} lg={2}>
                                <a className={`btn-primary ${location.pathname === "/collect-data" ? "active" : ""} btn rounded-pill`} href='/collect-data'>01 Collect</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/analyze-data' className={`btn-primary ${location.pathname === "/analyze-data" ? "active" : ""} btn rounded-pill`}>02 Analyze</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/report-data' className={`btn-primary ${location.pathname === "/report-data" ? "active" : ""} btn rounded-pill`}>03 Report</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/build-strategy' className={`btn-primary ${location.pathname === "/build-strategy" ? "active" : ""} btn rounded-pill`}>04 Build</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/reduce-emissions' className={`btn-primary ${location.pathname === "/reduce-emissions" ? "active" : ""} btn rounded-pill`}>05 Reduce</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/improve-strategy' className={`btn-primary ${location.pathname === "/improve-strategy" ? "active" : ""} btn rounded-pill`}>06 Improve</a>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <Footer />

        </div>
    )
}

export default BuildStrategy