import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { GoArrowRight } from "react-icons/go";
import { useLocation } from "react-router-dom";
import Header from '../../../shared/header/Header';
import Footer from '../../../shared/footer/Footer';
import './ProductDetails.scss';
import { BlogApiService, SubCategoryApiService } from '../../../services/apiService';
import { COVER_IMAGE_BASE_URL } from '../../../services/constants';

function AnalyzeData() {
    let location = useLocation();
    const [casestudy, setCaseStudy] = useState([]);
    const [subCategories, setSubCategories] = useState([])

    const getCaseStudyBlog = async () => {
        BlogApiService.getCaseStudyTopThree()
            .then(async (res) => {
                if (res.data) {
                    setCaseStudy(await res.data.blog);
                    console.log(casestudy)
                    console.log(res.data.blog);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    const getSubCategories = () => {
        SubCategoryApiService.getSubCategories()
            .then(response => {
                if (response.data.success === true) {
                    setSubCategories(response.data.data)
                }
            })
            .catch(e => {
                console.log(e)
            })
    }
    useEffect(() => {
        getSubCategories();
        getCaseStudyBlog();
    }, [])
    return (
        <div className='products-container ll-logo-display'>

            <Header />

            <div className='inner-hero'>
                <Container>
                    <div className='about-hero-txt'>
                        <div className='p-details-nav'>
                            <Row className='justify-content-center'>
                                <Col xs={4} md={3} lg={2}>
                                    <a className={`btn-primary ${location.pathname === "/collect-data" ? "active" : ""} btn rounded-pill`} href='/collect-data'>01 Collect</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/analyze-data' className={`btn-primary ${location.pathname === "/analyze-data" ? "active" : ""} btn rounded-pill`}>02 Analyze</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/report-data' className={`btn-primary ${location.pathname === "/report-data" ? "active" : ""} btn rounded-pill`}>03 Report</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/build-strategy' className={`btn-primary ${location.pathname === "/build-strategy" ? "active" : ""} btn rounded-pill`}>04 Build</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/reduce-emissions' className={`btn-primary ${location.pathname === "/reduce-emissions" ? "active" : ""} btn rounded-pill`}>05 Reduce</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/improve-strategy' className={`btn-primary ${location.pathname === "/improve-strategy" ? "active" : ""} btn rounded-pill`}>06 Improve</a>
                                </Col>
                            </Row>
                        </div>
                        <Row className='justify-content-center text-center'>
                            <Col lg={7} className='wow fadeInUp mt-5 mt-lg-0'>
                                <h1 className=''>Analyze Your Carbon Footprint with Custom Dashboards</h1>
                                <p className='mt-4'>Get a detailed view of your sustainability efforts through customizable dashboards and over 150 KPIs. Use our AI-powered bot to ask questions and gain deeper insights.</p>
                                <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>Start Analyze Carbon</a>
                            </Col>
                            <Col lg="11" className='text-center pt-5'>
                                <img src={'/assets/img/products/5.webp'} className='img-fluid details-img rounded-4' alt='Products banner' />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            {/* <section className='trustedby'>
                <div className='clients-section'>
                <div className="slider">
                <div className="slide-track">
                  <div className="slide"><img src={'/assets/img/clients/c1.png'} alt='Client 1'/></div>
                  <div className="slide"><img src={'/assets/img/clients/c2.png'} alt='Client 2'/></div>
                  <div className="slide"><img src={'/assets/img/clients/c3.png'} alt='Client 3'/></div>
                  <div className="slide"><img src={'/assets/img/clients/c4.png'} alt='Client 4'/></div>
                  <div className="slide"><img src={'/assets/img/clients/c5.png'} alt='Client 5'/></div>

                  <div className="slide"><img src={'/assets/img/clients/c1.png'} alt='Client 1'/></div>
                  <div className="slide"><img src={'/assets/img/clients/c2.png'} alt='Client 2'/></div>
                  <div className="slide"><img src={'/assets/img/clients/c3.png'} alt='Client 3'/></div>
                  <div className="slide"><img src={'/assets/img/clients/c4.png'} alt='Client 4'/></div>
                  <div className="slide"><img src={'/assets/img/clients/c5.png'} alt='Client 1'/></div>
                </div>
                </div>
                </div>
            </section> */}

            <section className='section-padd products-details-sec trans-bg'>
                <Container>
                    <Row>
                        <Col lg={5} className='wow fadeInRight order-lg-last'>
                            <img src={'/assets/img/products/analyze-data.svg'} className='img-fluid' alt='Collect Data' />
                        </Col>
                        <Col lg={7} className='align-self-center mt-5 mt-lg-0 wow fadeInUp'>
                            <h6 className='mb-3'>Introduction</h6>
                            <h2>Analyzing your carbon footprint is a critical step in your sustainability journey</h2>
                            <p className='mt-4'>This process involves examining emissions across all scopes to understand your company's impact on the environment. Our platform provides customizable dashboards and a wide range of KPIs to help you assess your footprint from sustainability, operational, and financial perspectives.</p>
                        </Col>
                        {/* <Col lg={1}></Col> */}
                    </Row>
                </Container>
            </section>

            <section className='section-padd-bottom savetime-section products-details-sec'>
                <Container>
                    <Row className='wow fadeInUp'>
                        <Col md={9}>
                            <h6 className='mb-4'>Our Solution</h6>
                            <h2>Bharat Carbon offers a powerful analysis platform with advanced features to understand your data better:</h2>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col md={4} className='wow fadeInUp'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>01</div>
                                        Our platform provides over 150 KPIs to help you evaluate your carbon footprint from multiple angles, including sustainability, operational efficiency, and financial performance.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className='mt-4 mt-md-0 wow fadeInDown'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>02</div>
                                        With customizable dashboards, you can create views tailored to your business needs, making it easier to track progress and identify areas for improvement.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className='mt-4 mt-md-0 wow fadeInUp'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>03</div>
                                        Our AI-driven bot allows you to interact with your data in a conversational way. Ask questions to get detailed analysis, enabling a deeper understanding of your carbon footprint.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='section-padd-bottom opportunity-section'>
                <Container>
                    <div className='details-slide'>
                        <Row>
                            <Col lg={6}>
                                <div className='oppor-left'>
                                    <h2>Why Is Calculating Emissions Across All Scopes Crucial?</h2>
                                    <p className='mt-4'>Accurate carbon accounting is key to successful decarbonization and regulatory compliance. Here's why measuring emissions across all scopes is so important:</p>
                                    <a href='/book-a-demo' className='btn btn-primary px-4 mt-5'>Start Analyze Carbon</a>
                                </div>
                            </Col>
                            <Col lg={6} className='pt-5 pt-lg-0'>
                                <div className='opportunity-box wow fadeInUp mt-0'>
                                    <Row className='mt-2'>
                                        <Col sm={12} className='align-self-center'>
                                            <div className='no-icon-c'><img src={'/assets/img/icons/meet-regulatory-requ.svg'} className="img-fluid" alt='Meet Regulatory Requirements' /></div>
                                            <h5 className='pb-3'>Meet Regulatory Requirements</h5>

                                            <div className='text-oppor'>By calculating emissions from scopes 1, 2, and 3, you can ensure compliance with current regulations and prepare for future ones. This reduces the risk of financial penalties and helps maintain a positive company reputation.</div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className='opportunity-box wow fadeInUp'>
                                    <Row className='mt-2'>
                                        <Col sm={12} className='align-self-center'>
                                            <div className='no-icon-c'><img src={'/assets/img/icons/miligate-climate-risks.svg'} className="img-fluid" alt='Mitigate Climate Risks' /></div>
                                            <h5 className='pb-3'>Mitigate Climate Risks</h5>

                                            <div className='text-oppor'>The global economy stands to lose up to $178 trillion by 2070 due to climate change. Accurate emissions data enables you to identify climate risks and take proactive measures to enhance resilience in a rapidly changing environment.</div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className='opportunity-box wow fadeInUp'>
                                    <Row className='mt-2'>
                                        <Col sm={12} className='align-self-center'>
                                            <div className='no-icon-c'><img src={'/assets/img/icons/reduce-cost-through-efficiency.svg'} className="img-fluid" alt='Reduce Costs Through Efficiency' /></div>
                                            <h5 className='pb-3'>Reduce Costs Through Efficiency</h5>
                                            <div className='text-oppor'>Pinpointing and quantifying CO2 emissions can reveal operational inefficiencies and hidden costs. Lowering emissions often leads to cost savings, as improved efficiency can boost profitability and reduce waste.</div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className='opportunity-box wow fadeInUp'>
                                    <Row className='mt-2'>
                                        <Col sm={12} className='align-self-center'>
                                            <div className='no-icon-c'><img src={'/assets/img/icons/engage-carbon-market.svg'} className="img-fluid" alt='Engage in Carbon Markets' /></div>
                                            <h5 className='pb-3'>Engage in Carbon Markets</h5>
                                            <div className='text-oppor'>As carbon trading systems grow, understanding your carbon footprint is crucial. Companies that track their emissions are better equipped to navigate carbon markets, avoid rising costs, and meet internal carbon pricing strategies.</div>
                                        </Col>
                                    </Row>
                                </div>

                            </Col>
                        </Row>
                    </div>

                </Container>

            </section>

            <section className='section-padd cta-section'>
                <Container>
                    <div className='text-center cta-text wow fadeInUp'>
                        <h2 className='mb-4 mb-lg-5'>Begin your decarbonisation journey today</h2>
                        <p>Our sustainability experts will advise you on an effective strategy for your business.</p>
                        <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>Book A Demo</a>
                    </div>
                </Container>
            </section>

            <section className='section-padd exploreStory'>
                <Container>
                    <h6 className='mb-4'>CASE STUDIES</h6>
                    {casestudy.length > 0 && <Row>
                        {casestudy.map((blog) =>
                            <Col lg={4} className='mt-5 wow fadeInUp'>
                                <div className='casestudy-box'>
                                    <div className='casestudy-image'>
                                        <img src={COVER_IMAGE_BASE_URL + blog?.coverimg} className='img-fluid' alt='case Study' />
                                    </div>
                                    <div className='casestudy-text'>
                                        <h6>{subCategories.find(sc => sc.id === parseInt(blog.subcategory))?.name}</h6>
                                        <h5>{blog.title}</h5>
                                        <a href={`/article/${blog.slug}`} className='btn btn-outline-primary px-4 mt-2 mt-lg-4'>Read Case Study <GoArrowRight /></a>
                                    </div>
                                </div>
                            </Col>
                        )
                        }
                    </Row>
                    }
                </Container>
            </section>

            <section className='footerpro-links'>
                <Container>
                    <div className='p-details-nav'>
                        <Row className='justify-content-center'>
                            <Col xs={4} md={3} lg={2}>
                                <a className={`btn-primary ${location.pathname === "/collect-data" ? "active" : ""} btn rounded-pill`} href='/collect-data'>01 Collect</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/analyze-data' className={`btn-primary ${location.pathname === "/analyze-data" ? "active" : ""} btn rounded-pill`}>02 Analyze</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/report-data' className={`btn-primary ${location.pathname === "/report-data" ? "active" : ""} btn rounded-pill`}>03 Report</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/build-strategy' className={`btn-primary ${location.pathname === "/build-strategy" ? "active" : ""} btn rounded-pill`}>04 Build</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/reduce-emissions' className={`btn-primary ${location.pathname === "/reduce-emissions" ? "active" : ""} btn rounded-pill`}>05 Reduce</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/improve-strategy' className={`btn-primary ${location.pathname === "/improve-strategy" ? "active" : ""} btn rounded-pill`}>06 Improve</a>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <Footer />

        </div>
    )
}

export default AnalyzeData